@charset "UTF-8";
/* Scss Document */
html,
body,
#root {
  height: 100%;
  width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Sawarabi Mincho" , serif !important;
  overflow-x: hidden; }

.App {
  position: relative;
  display: flex;
  flex-flow: column;
  text-align: center; }

.TopBar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 62px;
  padding: 0 24px; }

.TopBar a {
  margin-right: 18px;
  text-decoration: none; }

.btn-primary.custom-btn {
  background-color: #6898AD;
  border-color: #6898AD; }
  .btn-primary.custom-btn:hover, .btn-primary.custom-btn.hover {
    background-color: #598ca3;
    border-color: #6898AD; }
  .btn-primary.custom-btn:focus, .btn-primary.custom-btn.focus {
    background-color: #598ca3;
    border-color: #6898AD; }
  .btn-primary.custom-btn:visited, .btn-primary.custom-btn.visited {
    background-color: #598ca3; }
  .btn-primary.custom-btn:active, .btn-primary.custom-btn.visited {
    background-color: #598ca3; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #598ca3; }

.custom-btn {
  font-size: 0.8em; }

.handle-wrapper {
  width: auto;
  margin: 0 auto;
  max-width: 640px;
  padding: 500px 0; }

.test1 {
  font-size: 2.2em; }

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0; }

.navbar-toggler {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000; }
  .navbar-toggler span {
    display: block;
    background-color: #000;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1; }
    .navbar-toggler span:nth-child(1), .navbar-toggler span:nth-child(3) {
      -webkit-transition: transform .35s ease-in-out;
      -moz-transition: transform .35s ease-in-out;
      -o-transition: transform .35s ease-in-out;
      transition: transform .35s ease-in-out; }

.navbar-toggler:not(.collapsed) span {
  background-color: #FFF; }

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9; }

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent; }

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9; }

.navbar {
  padding: 0; }

.nav-wrap {
  overflow-y: scroll;
  height: 100vh; }

.nav-item {
  color: #FFF;
  background-color: #000; }
  .nav-item a {
    color: #FFF; }
  .nav-item .dropdown-menu {
    background-color: #000;
    color: #fff; }
    .nav-item .dropdown-menu .dropdown-item {
      color: #FFF; }
      .nav-item .dropdown-menu .dropdown-item:hover {
        background-color: #111; }
    .nav-item .dropdown-menu .dropdown-divider {
      border-top: 1px solid #111; }

.nav-margin-top {
  position: relative; }
  .nav-margin-top .btn-wrap {
    position: absolute;
    top: 0;
    right: 0;
    color: #FFF; }
    .nav-margin-top .btn-wrap a {
      display: inline-block;
      color: #FFF;
      font-size: 1.2rem;
      font-weight: light;
      margin: 0 8px; }
  .nav-margin-top .logo {
    margin: 0;
    padding: 25vh 0 15px 0;
    text-align: center; }
    .nav-margin-top .logo img {
      margin-top: 5px;
      width: 280px; }

.open-menu {
  display: block; }

.close-menu {
  display: none; }

.nav-suvery-group {
  color: #FFF;
  padding: 20px 0 5px 0;
  max-width: 650px;
  margin: 0 auto 0 auto; }
  .nav-suvery-group > div.row > div {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .nav-suvery-group a.nav-link {
    width: 100%;
    padding: 0;
    border: 1px solid transparent;
    display: block;
    height: auto;
    margin: 0px 0; }
  .nav-suvery-group .inner span {
    display: block;
    position: absolute;
    top: 40%;
    text-align: center;
    width: 100%; }
  .nav-suvery-group .inner img {
    opacity: 0.3;
    width: 100%; }
    .nav-suvery-group .inner img.answered {
      opacity: 0.8; }
    .nav-suvery-group .inner img.color-answered {
      opacity: 0; }
    .nav-suvery-group .inner img.center {
      opacity: 0.75; }
      .nav-suvery-group .inner img.center.answered {
        opacity: 1.0; }

.nav-margin-mid {
  height: 120px; }

.nav-margin-bottom {
  height: 80vh; }

.img-stretch {
  width: 100%; }

.row {
  margin-left: 0;
  margin-right: 0; }

.side-arrow-navs {
  width: 100%;
  height: 0;
  position: fixed;
  top: 50vh; }
  .side-arrow-navs .iconbox {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 0;
    font-size: 25px; }
    .side-arrow-navs .iconbox.icon-left-open-big {
      left: 0px; }
    .side-arrow-navs .iconbox.icon-right-open-big {
      right: 0px; }

.switch {
  font-size: 1rem;
  position: relative; }
  .switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0; }
    .switch input + label {
      position: relative;
      min-width: calc(calc(1.875rem * .8) * 2);
      border-radius: calc(1.875rem * .8);
      height: calc(1.875rem * .8);
      line-height: calc(1.875rem * .8);
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(calc(1.875rem * .8) * 2) + .5rem); }
    .switch input + label::before,
    .switch input + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(calc(1.875rem * .8) * 2);
      bottom: 0;
      display: block; }
    .switch input + label::before {
      right: 0;
      background-color: #aabbdd;
      border-radius: calc(1.875rem * .8);
      transition: 0.2s all; }
    .switch input + label::after {
      top: 2px;
      left: 2px;
      width: calc(calc(1.875rem * .8) - calc(2px * 2));
      height: calc(calc(1.875rem * .8) - calc(2px * 2));
      border-radius: 50%;
      background-color: white;
      transition: 0.2s all; }
    .switch input:checked + label::before {
      background-color: #aabbdd; }
    .switch input:checked + label::after {
      margin-left: calc(1.875rem * .8); }
    .switch input:focus + label::before {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(170, 187, 221, 0.25); }
    .switch input:disabled + label {
      color: #aabbdd;
      cursor: not-allowed; }
    .switch input:disabled + label::before {
      background-color: #aabbdd; }
  .switch.switch-sm {
    font-size: 0.875rem; }
    .switch.switch-sm input + label {
      min-width: calc(calc(1.9375rem * .8) * 2);
      height: calc(1.9375rem * .8);
      line-height: calc(1.9375rem * .8);
      text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem); }
    .switch.switch-sm input + label::before {
      width: calc(calc(1.9375rem * .8) * 2); }
    .switch.switch-sm input + label::after {
      width: calc(calc(1.9375rem * .8) - calc(2px * 2));
      height: calc(calc(1.9375rem * .8) - calc(2px * 2)); }
    .switch.switch-sm input:checked + label::after {
      margin-left: calc(1.9375rem * .8); }
  .switch.switch-lg {
    font-size: 1.25rem; }
    .switch.switch-lg input + label {
      min-width: calc(calc(3rem * .8) * 2);
      height: calc(3rem * .8);
      line-height: calc(3rem * .8);
      text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem); }
    .switch.switch-lg input + label::before {
      width: calc(calc(3rem * .8) * 2); }
    .switch.switch-lg input + label::after {
      width: calc(calc(3rem * .8) - calc(2px * 2));
      height: calc(calc(3rem * .8) - calc(2px * 2)); }
    .switch.switch-lg input:checked + label::after {
      margin-left: calc(3rem * .8); }
  .switch + .switch {
    margin-left: 1rem; }

.icon-left-open-mini:before {
  content: '\e800'; }

/* '' */
.icon-user-3:before {
  content: '\e801'; }

/* '' */
.icon-user-2:before {
  content: '\e802'; }

/* '' */
.icon-down-open:before {
  content: '\e803'; }

/* '' */
.icon-ie:before {
  content: '\e804'; }

/* '' */
.icon-opera:before {
  content: '\e805'; }

/* '' */
.icon-chrome:before {
  content: '\e806'; }

/* '' */
.icon-firefox:before {
  content: '\e807'; }

/* '' */
.icon-spin6:before {
  content: '\e808'; }

/* '' */
.icon-spin4:before {
  content: '\e809'; }

/* '' */
.icon-spin5:before {
  content: '\e80a'; }

/* '' */
.icon-spin3:before {
  content: '\e80b'; }

/* '' */
.icon-spin2:before {
  content: '\e80c'; }

/* '' */
.icon-spin1:before {
  content: '\e80d'; }

/* '' */
.icon-right-open-mini:before {
  content: '\e80e'; }

/* '' */
.icon-up-open-mini:before {
  content: '\e80f'; }

/* '' */
.icon-down-open-big:before {
  content: '\e810'; }

/* '' */
.icon-left-open-big:before {
  content: '\e811'; }

/* '' */
.icon-right-open-big:before {
  content: '\e812'; }

/* '' */
.icon-up-open-big:before {
  content: '\e813'; }

/* '' */
.icon-down-5:before {
  content: '\e814'; }

/* '' */
.icon-left-5:before {
  content: '\e815'; }

/* '' */
.icon-right-4:before {
  content: '\e816'; }

/* '' */
.icon-up-5:before {
  content: '\e817'; }

/* '' */
.icon-down-dir-2:before {
  content: '\e818'; }

/* '' */
.icon-left-dir-1:before {
  content: '\e819'; }

/* '' */
.icon-right-dir-2:before {
  content: '\e81a'; }

/* '' */
.icon-up-dir-2:before {
  content: '\e81b'; }

/* '' */
.icon-lamp:before {
  content: '\e81c'; }

/* '' */
.icon-light-down:before {
  content: '\e81d'; }

/* '' */
.icon-window:before {
  content: '\e81e'; }

/* '' */
.icon-arrow-combo:before {
  content: '\e81f'; }

/* '' */
.icon-down-bold-1:before {
  content: '\e820'; }

/* '' */
.icon-left-bold-1:before {
  content: '\e821'; }

/* '' */
.icon-right-bold-1:before {
  content: '\e822'; }

/* '' */
.icon-up-bold-1:before {
  content: '\e823'; }

/* '' */
.icon-down-circled-1:before {
  content: '\e824'; }

/* '' */
.icon-left-circled-1:before {
  content: '\e825'; }

/* '' */
.icon-right-circled-1:before {
  content: '\e826'; }

/* '' */
.icon-up-circled-1:before {
  content: '\e827'; }

/* '' */
.icon-down-open-2:before {
  content: '\e828'; }

/* '' */
.icon-left-open-4:before {
  content: '\e829'; }

/* '' */
.icon-right-open-4:before {
  content: '\e82a'; }

/* '' */
.icon-up-open-2:before {
  content: '\e82b'; }

/* '' */
.icon-down-open-mini:before {
  content: '\e82c'; }

/* '' */
.icon-light-up:before {
  content: '\e82d'; }

/* '' */
.icon-adjust-1:before {
  content: '\e82e'; }

/* '' */
.icon-block-4:before {
  content: '\e82f'; }

/* '' */
.icon-resize-full-5:before {
  content: '\e830'; }

/* '' */
.icon-resize-small-3:before {
  content: '\e831'; }

/* '' */
.icon-popup-5:before {
  content: '\e832'; }

/* '' */
.icon-publish:before {
  content: '\e833'; }

/* '' */
.icon-folder-1:before {
  content: '\e834'; }

/* '' */
.icon-archive-2:before {
  content: '\e835'; }

/* '' */
.icon-box-3:before {
  content: '\e836'; }

/* '' */
.icon-rss-6:before {
  content: '\e837'; }

/* '' */
.icon-phone-2:before {
  content: '\e838'; }

/* '' */
.icon-cog-5:before {
  content: '\e839'; }

/* '' */
.icon-tools:before {
  content: '\e83a'; }

/* '' */
.icon-share-2:before {
  content: '\e83b'; }

/* '' */
.icon-shareable:before {
  content: '\e83c'; }

/* '' */
.icon-basket-3:before {
  content: '\e83d'; }

/* '' */
.icon-bag:before {
  content: '\e83e'; }

/* '' */
.icon-calendar-6:before {
  content: '\e83f'; }

/* '' */
.icon-login-2:before {
  content: '\e840'; }

/* '' */
.icon-logout-2:before {
  content: '\e841'; }

/* '' */
.icon-mic-4:before {
  content: '\e842'; }

/* '' */
.icon-mute:before {
  content: '\e843'; }

/* '' */
.icon-sound:before {
  content: '\e844'; }

/* '' */
.icon-volume-1:before {
  content: '\e845'; }

/* '' */
.icon-clock-6:before {
  content: '\e846'; }

/* '' */
.icon-hourglass-1:before {
  content: '\e847'; }

/* '' */
.icon-chat:before {
  content: '\e848'; }

/* '' */
.icon-bell-4:before {
  content: '\e849'; }

/* '' */
.icon-attention-4:before {
  content: '\e84a'; }

/* '' */
.icon-alert:before {
  content: '\e84b'; }

/* '' */
.icon-vcard-1:before {
  content: '\e84c'; }

/* '' */
.icon-address-1:before {
  content: '\e84d'; }

/* '' */
.icon-location-6:before {
  content: '\e84e'; }

/* '' */
.icon-map-1:before {
  content: '\e84f'; }

/* '' */
.icon-direction-2:before {
  content: '\e850'; }

/* '' */
.icon-compass-3:before {
  content: '\e851'; }

/* '' */
.icon-cup:before {
  content: '\e852'; }

/* '' */
.icon-trash-5:before {
  content: '\e853'; }

/* '' */
.icon-doc-6:before {
  content: '\e854'; }

/* '' */
.icon-docs:before {
  content: '\e855'; }

/* '' */
.icon-doc-landscape:before {
  content: '\e856'; }

/* '' */
.icon-doc-text-1:before {
  content: '\e857'; }

/* '' */
.icon-doc-text-inv:before {
  content: '\e858'; }

/* '' */
.icon-newspaper-1:before {
  content: '\e859'; }

/* '' */
.icon-book-open-1:before {
  content: '\e85a'; }

/* '' */
.icon-book-3:before {
  content: '\e85b'; }

/* '' */
.icon-cancel-circled-1:before {
  content: '\e85c'; }

/* '' */
.icon-cancel-squared:before {
  content: '\e85d'; }

/* '' */
.icon-plus-5:before {
  content: '\e85e'; }

/* '' */
.icon-plus-circled-1:before {
  content: '\e85f'; }

/* '' */
.icon-mail:before {
  content: '\e860'; }

/* '' */
.icon-plus-squared:before {
  content: '\e861'; }

/* '' */
.icon-minus-3:before {
  content: '\e862'; }

/* '' */
.icon-minus-circled-1:before {
  content: '\e863'; }

/* '' */
.icon-minus-squared:before {
  content: '\e864'; }

/* '' */
.icon-help-3:before {
  content: '\e865'; }

/* '' */
.icon-help-circled-2:before {
  content: '\e866'; }

/* '' */
.icon-info-3:before {
  content: '\e867'; }

/* '' */
.icon-info-circled-2:before {
  content: '\e868'; }

/* '' */
.icon-back:before {
  content: '\e869'; }

/* '' */
.icon-home-5:before {
  content: '\e86a'; }

/* '' */
.icon-link-4:before {
  content: '\e86b'; }

/* '' */
.icon-attach-5:before {
  content: '\e86c'; }

/* '' */
.icon-lock-6:before {
  content: '\e86d'; }

/* '' */
.icon-lock-open-6:before {
  content: '\e86e'; }

/* '' */
.icon-eye-5:before {
  content: '\e86f'; }

/* '' */
.icon-tag-5:before {
  content: '\e870'; }

/* '' */
.icon-bookmark-1:before {
  content: '\e871'; }

/* '' */
.icon-bookmarks:before {
  content: '\e872'; }

/* '' */
.icon-flag-3:before {
  content: '\e873'; }

/* '' */
.icon-heart:before {
  content: '\e874'; }

/* '' */
.icon-thumbs-up-3:before {
  content: '\e875'; }

/* '' */
.icon-thumbs-down-3:before {
  content: '\e876'; }

/* '' */
.icon-download-5:before {
  content: '\e877'; }

/* '' */
.icon-upload-4:before {
  content: '\e878'; }

/* '' */
.icon-upload-cloud-3:before {
  content: '\e879'; }

/* '' */
.icon-reply-3:before {
  content: '\e87a'; }

/* '' */
.icon-reply-all-1:before {
  content: '\e87b'; }

/* '' */
.icon-forward-3:before {
  content: '\e87c'; }

/* '' */
.icon-quote-1:before {
  content: '\e87d'; }

/* '' */
.icon-code-2:before {
  content: '\e87e'; }

/* '' */
.icon-export-4:before {
  content: '\e87f'; }

/* '' */
.icon-pencil-5:before {
  content: '\e880'; }

/* '' */
.icon-feather-1:before {
  content: '\e881'; }

/* '' */
.icon-print-5:before {
  content: '\e882'; }

/* '' */
.icon-retweet-3:before {
  content: '\e883'; }

/* '' */
.icon-keyboard:before {
  content: '\e884'; }

/* '' */
.icon-comment-5:before {
  content: '\e885'; }

/* '' */
.icon-left-thin:before {
  content: '\e886'; }

/* '' */
.icon-right-thin:before {
  content: '\e887'; }

/* '' */
.icon-heart-empty:before {
  content: '\e888'; }

/* '' */
.icon-up-thin:before {
  content: '\e889'; }

/* '' */
.icon-ccw-1:before {
  content: '\e88a'; }

/* '' */
.icon-cw-4:before {
  content: '\e88b'; }

/* '' */
.icon-arrows-ccw:before {
  content: '\e88c'; }

/* '' */
.icon-level-down:before {
  content: '\e88d'; }

/* '' */
.icon-level-up:before {
  content: '\e88e'; }

/* '' */
.icon-shuffle-4:before {
  content: '\e88f'; }

/* '' */
.icon-loop-2:before {
  content: '\e890'; }

/* '' */
.icon-switch:before {
  content: '\e891'; }

/* '' */
.icon-left-open-outline:before {
  content: '\e892'; }

/* '' */
.icon-left-open-3:before {
  content: '\e893'; }

/* '' */
.icon-right-open-outline:before {
  content: '\e894'; }

/* '' */
.icon-right-open-3:before {
  content: '\e895'; }

/* '' */
.icon-down-4:before {
  content: '\e896'; }

/* '' */
.icon-left-4:before {
  content: '\e897'; }

/* '' */
.icon-right-1:before {
  content: '\e898'; }

/* '' */
.icon-up-4:before {
  content: '\e899'; }

/* '' */
.icon-down-outline:before {
  content: '\e89a'; }

/* '' */
.icon-left-outline:before {
  content: '\e89b'; }

/* '' */
.icon-star:before {
  content: '\e89c'; }

/* '' */
.icon-right-outline:before {
  content: '\e89d'; }

/* '' */
.icon-up-outline:before {
  content: '\e89e'; }

/* '' */
.icon-down-small:before {
  content: '\e89f'; }

/* '' */
.icon-left-small:before {
  content: '\e8a0'; }

/* '' */
.icon-right-small:before {
  content: '\e8a1'; }

/* '' */
.icon-up-small:before {
  content: '\e8a2'; }

/* '' */
.icon-left-open:before {
  content: '\e8a3'; }

/* '' */
.icon-right-open:before {
  content: '\e8a4'; }

/* '' */
.icon-up-open:before {
  content: '\e8a5'; }

/* '' */
.icon-down:before {
  content: '\e8a6'; }

/* '' */
.icon-left:before {
  content: '\e8a7'; }

/* '' */
.icon-right:before {
  content: '\e8a8'; }

/* '' */
.icon-up:before {
  content: '\e8a9'; }

/* '' */
.icon-down-circled:before {
  content: '\e8aa'; }

/* '' */
.icon-left-circled:before {
  content: '\e8ab'; }

/* '' */
.icon-right-circled:before {
  content: '\e8ac'; }

/* '' */
.icon-align-left:before {
  content: '\e8ad'; }

/* '' */
.icon-align-center:before {
  content: '\e8ae'; }

/* '' */
.icon-align-right:before {
  content: '\e8af'; }

/* '' */
.icon-user:before {
  content: '\e8b0'; }

/* '' */
.icon-align-justify:before {
  content: '\e8b1'; }

/* '' */
.icon-list:before {
  content: '\e8b2'; }

/* '' */
.icon-indent-left:before {
  content: '\e8b3'; }

/* '' */
.icon-indent-right:before {
  content: '\e8b4'; }

/* '' */
.icon-video:before {
  content: '\e8ba'; }

/* '' */
.icon-picture-1:before {
  content: '\e8c4'; }

/* '' */
.icon-th-large-1:before {
  content: '\e8ce'; }

/* '' */
.icon-th-1:before {
  content: '\e8d8'; }

/* '' */
.icon-th-list-1:before {
  content: '\e8e2'; }

/* '' */
.icon-facebook-1:before {
  content: '\e94b'; }

/* '' */
.icon-blogger-1:before {
  content: '\e94c'; }

/* '' */
.icon-visa:before {
  content: '\e94d'; }

/* '' */
.icon-ok-2:before {
  content: '\e94f'; }

/* '' */
.icon-ok-circle:before {
  content: '\e950'; }

/* '' */
.icon-cancel-1:before {
  content: '\e951'; }

/* '' */
.icon-cancel-circle:before {
  content: '\e952'; }

/* '' */
.icon-plus-circle:before {
  content: '\e953'; }

/* '' */
.icon-minus-circle:before {
  content: '\e954'; }

/* '' */
.icon-link-1:before {
  content: '\e955'; }

/* '' */
.icon-attach-1:before {
  content: '\e956'; }

/* '' */
.icon-lock-1:before {
  content: '\e957'; }

/* '' */
.icon-lock-open-1:before {
  content: '\e958'; }

/* '' */
.icon-tag-1:before {
  content: '\e959'; }

/* '' */
.icon-reply:before {
  content: '\e95a'; }

/* '' */
.icon-reply-all:before {
  content: '\e95b'; }

/* '' */
.icon-forward-1:before {
  content: '\e95c'; }

/* '' */
.icon-code-1:before {
  content: '\e95d'; }

/* '' */
.icon-retweet-1:before {
  content: '\e95e'; }

/* '' */
.icon-comment-1:before {
  content: '\e95f'; }

/* '' */
.icon-comment-alt-1:before {
  content: '\e960'; }

/* '' */
.icon-chat-1:before {
  content: '\e961'; }

/* '' */
.icon-attention:before {
  content: '\e962'; }

/* '' */
.icon-location-2:before {
  content: '\e963'; }

/* '' */
.icon-doc-1:before {
  content: '\e964'; }

/* '' */
.icon-docs-landscape:before {
  content: '\e965'; }

/* '' */
.icon-folder-2:before {
  content: '\e966'; }

/* '' */
.icon-archive:before {
  content: '\e967'; }

/* '' */
.icon-rss-2:before {
  content: '\e968'; }

/* '' */
.icon-rss-alt:before {
  content: '\e969'; }

/* '' */
.icon-cog:before {
  content: '\e96a'; }

/* '' */
.icon-logout:before {
  content: '\e96b'; }

/* '' */
.icon-clock-1:before {
  content: '\e96c'; }

/* '' */
.icon-block:before {
  content: '\e96d'; }

/* '' */
.icon-resize-full-1:before {
  content: '\e96e'; }

/* '' */
.icon-resize-full-circle:before {
  content: '\e96f'; }

/* '' */
.icon-popup:before {
  content: '\e970'; }

/* '' */
.icon-left-open-1:before {
  content: '\e971'; }

/* '' */
.icon-right-open-1:before {
  content: '\e972'; }

/* '' */
.icon-down-circle:before {
  content: '\e973'; }

/* '' */
.icon-left-circle:before {
  content: '\e974'; }

/* '' */
.icon-right-circle:before {
  content: '\e975'; }

/* '' */
.icon-up-circle:before {
  content: '\e976'; }

/* '' */
.icon-down-dir:before {
  content: '\e977'; }

/* '' */
.icon-right-dir-1:before {
  content: '\e978'; }

/* '' */
.icon-down-micro:before {
  content: '\e979'; }

/* '' */
.icon-up-micro:before {
  content: '\e97a'; }

/* '' */
.icon-cw-circle:before {
  content: '\e97b'; }

/* '' */
.icon-arrows-cw-1:before {
  content: '\e97c'; }

/* '' */
.icon-updown-circle:before {
  content: '\e97d'; }

/* '' */
.icon-target-1:before {
  content: '\e97e'; }

/* '' */
.icon-signal-1:before {
  content: '\e97f'; }

/* '' */
.icon-progress-0:before {
  content: '\e980'; }

/* '' */
.icon-progress-2:before {
  content: '\e981'; }

/* '' */
.icon-progress-3:before {
  content: '\e982'; }

/* '' */
.icon-progress-4:before {
  content: '\e983'; }

/* '' */
.icon-progress-5:before {
  content: '\e984'; }

/* '' */
.icon-progress-6:before {
  content: '\e985'; }

/* '' */
.icon-progress-7:before {
  content: '\e986'; }

/* '' */
.icon-progress-8:before {
  content: '\e987'; }

/* '' */
.icon-font-2:before {
  content: '\e988'; }

/* '' */
.icon-list-1:before {
  content: '\e989'; }

/* '' */
.icon-list-numbered:before {
  content: '\e98a'; }

/* '' */
.icon-indent-left-1:before {
  content: '\e98b'; }

/* '' */
.icon-indent-right-1:before {
  content: '\e98c'; }

/* '' */
.icon-cloud-1:before {
  content: '\e98d'; }

/* '' */
.icon-terminal:before {
  content: '\e98e'; }

/* '' */
.icon-facebook-rect:before {
  content: '\e98f'; }

/* '' */
.icon-twitter-bird:before {
  content: '\e990'; }

/* '' */
.icon-vimeo-rect:before {
  content: '\e991'; }

/* '' */
.icon-tumblr-rect:before {
  content: '\e992'; }

/* '' */
.icon-googleplus-rect:before {
  content: '\e993'; }

/* '' */
.icon-linkedin-rect:before {
  content: '\e994'; }

/* '' */
.icon-skype:before {
  content: '\e995'; }

/* '' */
.icon-vkontakte-rect:before {
  content: '\e996'; }

/* '' */
.icon-youtube-1:before {
  content: '\e997'; }

/* '' */
.icon-odnoklassniki-rect:before {
  content: '\e998'; }

/* '' */
.icon-search-3:before {
  content: '\e999'; }

/* '' */
.icon-facebook-rect-1:before {
  content: '\e9c9'; }

/* '' */
.icon-twitter-1:before {
  content: '\e9ca'; }

/* '' */
.icon-twitter-bird-1:before {
  content: '\e9cb'; }

/* '' */
.icon-vimeo-1:before {
  content: '\e9cc'; }

/* '' */
.icon-vimeo-rect-1:before {
  content: '\e9cd'; }

/* '' */
.icon-tumblr-1:before {
  content: '\e9ce'; }

/* '' */
.icon-tumblr-rect-1:before {
  content: '\e9cf'; }

/* '' */
.icon-googleplus-rect-1:before {
  content: '\e9d0'; }

/* '' */
.icon-github-text-1:before {
  content: '\e9d1'; }

/* '' */
.icon-github-1:before {
  content: '\e9d2'; }

/* '' */
.icon-skype-2:before {
  content: '\e9d3'; }

/* '' */
.icon-icq:before {
  content: '\e9d4'; }

/* '' */
.icon-yandex:before {
  content: '\e9d5'; }

/* '' */
.icon-yandex-rect:before {
  content: '\e9d6'; }

/* '' */
.icon-vkontakte-rect-1:before {
  content: '\e9d7'; }

/* '' */
.icon-odnoklassniki:before {
  content: '\e9d8'; }

/* '' */
.icon-odnoklassniki-rect-1:before {
  content: '\e9d9'; }

/* '' */
.icon-friendfeed-1:before {
  content: '\e9da'; }

/* '' */
.icon-friendfeed-rect-1:before {
  content: '\e9db'; }

/* '' */
.icon-blogger-rect:before {
  content: '\e9dc'; }

/* '' */
.icon-deviantart-1:before {
  content: '\e9dd'; }

/* '' */
.icon-jabber:before {
  content: '\e9de'; }

/* '' */
.icon-lastfm:before {
  content: '\e9df'; }

/* '' */
.icon-lastfm-rect:before {
  content: '\e9e0'; }

/* '' */
.icon-linkedin-3:before {
  content: '\e9e1'; }

/* '' */
.icon-linkedin-rect-1:before {
  content: '\e9e2'; }

/* '' */
.icon-picasa-1:before {
  content: '\e9e3'; }

/* '' */
.icon-wordpress-1:before {
  content: '\e9e4'; }

/* '' */
.icon-instagram-1:before {
  content: '\e9e5'; }

/* '' */
.icon-instagram-filled:before {
  content: '\e9e6'; }

/* '' */
.icon-diigo:before {
  content: '\e9e7'; }

/* '' */
.icon-box:before {
  content: '\e9e8'; }

/* '' */
.icon-box-rect:before {
  content: '\e9e9'; }

/* '' */
.icon-tudou:before {
  content: '\e9ea'; }

/* '' */
.icon-youku:before {
  content: '\e9eb'; }

/* '' */
.icon-win8:before {
  content: '\e9ec'; }

/* '' */
.icon-amex:before {
  content: '\e9ed'; }

/* '' */
.icon-discover:before {
  content: '\e9ee'; }

/* '' */
.icon-mastercard:before {
  content: '\e9ef'; }

/* '' */
.icon-houzz:before {
  content: '\e9f0'; }

/* '' */
.icon-bandcamp:before {
  content: '\e9f1'; }

/* '' */
.icon-codepen:before {
  content: '\e9f2'; }

/* '' */
.slick-prev:before,
.slick-next:before {
  font-family: 'newuiset';
  color: #000FFF; }

.slick-prev {
  left: 10px;
  width: 30px;
  height: 30px;
  z-index: 100; }
  .slick-prev:before {
    display: block;
    content: '';
    background-image: url(sqarrowL.svg) !important;
    width: 30px;
    height: 30px; }

.slick-next {
  right: 10px;
  width: 30px;
  height: 30px;
  z-index: 100; }
  .slick-next:before {
    content: '';
    display: block;
    content: '';
    background-image: url(sqarrow.svg) !important;
    width: 30px;
    height: 30px; }

.navbar .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.form-wrapper {
  max-width: 320px;
  margin: 0 auto;
  padding: 0 0 25px 0; }
  .form-wrapper form label.mr {
    margin-right: 15px; }
  .form-wrapper form .form-control {
    font-size: 0.9rem; }

.init-home .home-header {
  padding: 25vh 0 0vh 0; }
  .init-home .home-header h1 {
    margin: 0;
    padding: 0 0 15px 0;
    text-align: center; }
    .init-home .home-header h1 img {
      width: 280px; }
  .init-home .home-header h2 {
    font-size: 0.8em; }
  .init-home .home-header p {
    width: 80%;
    margin: 0 auto; }

.init-home .home-content {
  padding: 5vh 0 0vh 0; }
  .init-home .home-content h2 {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    line-height: 1.4; }
  .init-home .home-content p {
    font-size: 0.95rem; }
  .init-home .home-content .form-wrapper {
    max-width: 320px;
    margin: 0 auto;
    padding: 0 0 25px 0; }
    .init-home .home-content .form-wrapper form label {
      margin-right: 15px; }

.alert {
  font-size: 0.8rem; }

.init-quiz .header {
  overflow: auto;
  padding: 20vh 0 0vh 0; }
  .init-quiz .header h2 {
    font-size: 1.65rem;
    margin: 0 15px 25px 15px;
    padding: 0 0 1.0rem 0;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .init-quiz .header h2 {
        font-size: 1.75rem; } }
  .init-quiz .header h3 {
    font-size: 1.30rem;
    max-width: 400px;
    margin: 0 auto 45px auto;
    padding: 0 0 1.0rem 0;
    line-height: 1.55; }
    @media (min-width: 768px) {
      .init-quiz .header h3 {
        font-size: 1.55rem;
        max-width: 400px;
        margin: 0 auto 45px auto; } }
  .init-quiz .header p {
    font-size: 0.95rem;
    margin-bottom: 50px; }
  .init-quiz .header .switch-wrap {
    padding: 25px 0; }

.init-quiz .content {
  margin: 0 15px; }
  @media (min-width: 576px) {
    .init-quiz .content {
      margin: 0 auto;
      max-width: 680px; } }
  .init-quiz .content .home-brands {
    margin: 20vh 15px 0 15px; }
  .init-quiz .content .eachbrand {
    float: left;
    width: 33%;
    height: 150px; }
    @media (min-width: 576px) {
      .init-quiz .content .eachbrand {
        width: 25%;
        height: 150px; } }
    .init-quiz .content .eachbrand .inner {
      margin: 0 5px; }
    .init-quiz .content .eachbrand img {
      width: 100%;
      max-width: 100%; }

.btn-lg {
  width: 240px; }

.setup-quiz .header {
  overflow: auto;
  padding: 20vh 0 0vh 0; }
  .setup-quiz .header h2 {
    font-size: 1.3rem;
    padding: 0 0 1.0rem 0;
    line-height: 1.4;
    width: 80%;
    margin: 0 10% 25px 10%; }
  .setup-quiz .header p {
    font-size: 0.95rem; }
  .setup-quiz .header.more-space {
    padding-top: 25vh; }

.setup-quiz .content .bold {
  font-weight: 900; }

.report-init .header {
  padding: 15vh 0 10vh 0;
  margin: 0 25px; }
  .report-init .header h1 {
    margin: 0;
    padding: 0 0 10px 0;
    text-align: center; }
    .report-init .header h1 img {
      width: 280px; }
  .report-init .header h3 {
    padding: 0px 0 0vh 0;
    font-size: 0.9rem;
    line-height: 1.6; }

.report-init .content .surveylist-wrap .date {
  font-size: 0.75rem; }

.report-init .content .surveylist-wrap .sex {
  font-size: 1.0rem; }

.report-init .content .surveylist-wrap .code {
  font-size: 1.0rem; }

.report-init .content .surveylist-wrap table tbody tr th, .report-init .content .surveylist-wrap table tbody tr td, .report-init .content .surveylist-wrap table thead tr th, .report-init .content .surveylist-wrap table thead tr td {
  text-align: left;
  vertical-align: middle;
  line-height: 1.2; }
  .report-init .content .surveylist-wrap table tbody tr th.th-date, .report-init .content .surveylist-wrap table tbody tr td.th-date, .report-init .content .surveylist-wrap table thead tr th.th-date, .report-init .content .surveylist-wrap table thead tr td.th-date {
    min-width: 90px; }
  .report-init .content .surveylist-wrap table tbody tr th.th-sex, .report-init .content .surveylist-wrap table tbody tr td.th-sex, .report-init .content .surveylist-wrap table thead tr th.th-sex, .report-init .content .surveylist-wrap table thead tr td.th-sex {
    min-width: 60px; }
  .report-init .content .surveylist-wrap table tbody tr th.th-code, .report-init .content .surveylist-wrap table tbody tr td.th-code, .report-init .content .surveylist-wrap table thead tr th.th-code, .report-init .content .surveylist-wrap table thead tr td.th-code {
    min-width: 120px; }
  .report-init .content .surveylist-wrap table tbody tr th.th-button, .report-init .content .surveylist-wrap table tbody tr td.th-button, .report-init .content .surveylist-wrap table thead tr th.th-button, .report-init .content .surveylist-wrap table thead tr td.th-button {
    min-width: 120px; }
  .report-init .content .surveylist-wrap table tbody tr th.th-long, .report-init .content .surveylist-wrap table tbody tr td.th-long, .report-init .content .surveylist-wrap table thead tr th.th-long, .report-init .content .surveylist-wrap table thead tr td.th-long {
    min-width: 320px; }

.report-init .content .surveylist-wrap table tbody tr th, .report-init .content .surveylist-wrap table thead tr th {
  font-size: 0.8rem; }

.report-init .content .surveylist-wrap .pagination-wrap {
  text-align: center;
  padding: 25px 0; }

.report-init .each-report {
  max-width: 280px;
  margin: 0 auto;
  padding: 50px 0px; }
  .report-init .each-report h4 {
    font-size: 1.3em;
    text-align: center;
    max-width: 280px;
    margin: 0 auto; }

.each-quiz .quiz-header {
  height: 45vh; }
  .each-quiz .quiz-header h3 {
    padding: 21vh 0 0vh 0;
    font-size: 1.3rem; }
    @media (min-width: 576px) {
      .each-quiz .quiz-header h3 {
        font-size: 1.5rem; } }
    @media (min-width: 768px) {
      .each-quiz .quiz-header h3 {
        font-size: 1.9rem; } }
  .each-quiz .quiz-header .selected-answer {
    padding: 5vh 0 5vh 0; }
    .each-quiz .quiz-header .selected-answer span {
      font-size: 15px;
      margin-right: 10px; }

.each-quiz.color-style .color-block-wrap {
  width: 80%;
  margin: 10vh 10% 0 10%;
  padding: 0 0 0 0;
  background-color: #000; }
  .each-quiz.color-style .color-block-wrap .color-block {
    position: relative;
    float: left;
    width: calc(100% / 7);
    opacity: 1.0;
    border-bottom: 1px solid #FFF; }
    .each-quiz.color-style .color-block-wrap .color-block img {
      cursor: pointer;
      width: 100%; }

@keyframes borderanime {
  0% {
    border-color: white; }
  20% {
    border-color: #adadad; }
  100% {
    border-color: #FFF; } }
    .each-quiz.color-style .color-block-wrap .color-block.focus {
      opacity: 0.95;
      border-bottom: 1px solid #999; }

.each-quiz.select-style .select-block-wrap {
  width: 80%;
  margin: 10vh 10% 0 10%;
  padding: 0 0 0 0; }

.each-quiz.style1 .quiz-header {
  margin: 0 15px; }
  .each-quiz.style1 .quiz-header .selected-answer {
    margin: 0 15px; }
    .each-quiz.style1 .quiz-header .selected-answer span {
      margin-right: 10px; }

.each-quiz .image-block-sq {
  padding: 2.5vh 5vh; }
  .each-quiz .image-block-sq .inner {
    min-height: 240px;
    border: 5px solid #FAFAFA; }

@keyframes example {
  0% {
    border-color: white; }
  20% {
    border-color: #7bcbd5; }
  100% {
    border-color: #333; } }
    .each-quiz .image-block-sq .inner.focus {
      border: 5px solid #333;
      position: relative;
      animation-name: example;
      animation-duration: 1s; }
      .each-quiz .image-block-sq .inner.focus .overlay img {
        position: relative; }

.each-quiz .overlay {
  display: block;
  height: 100%; }
  .each-quiz .overlay img {
    cursor: pointer; }

.each-quiz .slider-block-wrap {
  padding: 0 0 10vh 0; }
  .each-quiz .slider-block-wrap .header .thumb-wrap {
    text-align: left;
    padding: 20px 0;
    margin: 0 25px; }
    .each-quiz .slider-block-wrap .header .thumb-wrap .thum {
      position: relative;
      display: block;
      width: 100%;
      border: 1px solid transparent;
      margin-bottom: 10px;
      padding: 2px; }
      .each-quiz .slider-block-wrap .header .thumb-wrap .thum.active {
        border: 1px solid #CCC; }
      .each-quiz .slider-block-wrap .header .thumb-wrap .thum img {
        width: auto;
        max-width: 100%; }
    .each-quiz .slider-block-wrap .header .thumb-wrap .slick-thum {
      position: relative;
      display: inline-block;
      width: 24.9%; }
      .each-quiz .slider-block-wrap .header .thumb-wrap .slick-thum .corner {
        font-size: 11px; }
        .each-quiz .slider-block-wrap .header .thumb-wrap .slick-thum .corner i {
          font-size: 10px;
          color: #DADADA; }
      .each-quiz .slider-block-wrap .header .thumb-wrap .slick-thum span {
        display: block;
        min-height: 50px;
        font-size: 10px;
        line-height: 1.3;
        margin: 0 5px;
        color: #666; }
      .each-quiz .slider-block-wrap .header .thumb-wrap .slick-thum .thum.active {
        border: 1px solid #EEEEEE; }
  .each-quiz .slider-block-wrap .header .slick-wrap {
    padding: 0 0 2.5vh 0;
    width: auto;
    max-width: 660px;
    margin: 0 auto; }
  .each-quiz .slider-block-wrap .form-wrapper {
    max-width: 320px;
    margin: 0 auto;
    padding: 0 0 25px 0; }
    .each-quiz .slider-block-wrap .form-wrapper form label {
      margin-right: 15px; }
  .each-quiz .slider-block-wrap .content {
    padding: 25px 0;
    margin: 0 25px; }
    .each-quiz .slider-block-wrap .content .slider-block {
      width: auto;
      margin: 0 auto;
      max-width: 580px;
      padding: 15px 0 15px 0; }
      .each-quiz .slider-block-wrap .content .slider-block .slider-wrap {
        height: auto;
        padding: 15px 0;
        margin-bottom: 20px; }
        .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 {
          position: relative;
          text-align: left;
          font-size: 0.8em;
          padding: 10px 5px 5px 55px; }
          @media (min-width: 768px) {
            .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 {
              font-size: 0.9em;
              padding: 50px 15px 15px 95px; } }
          .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 span.sq-thum {
            position: absolute;
            left: 0;
            top: -10px;
            display: block;
            width: 50px; }
            .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 span.sq-thum img {
              width: 50px; }
            @media (min-width: 768px) {
              .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 span.sq-thum {
                top: 10px;
                width: 80px; }
                .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 span.sq-thum img {
                  width: 80px; } }
          .each-quiz .slider-block-wrap .content .slider-block .slider-wrap h4 span.delete-icon {
            color: #CCC;
            position: absolute;
            display: block;
            width: 40px;
            top: 10px;
            right: -30px; }
      .each-quiz .slider-block-wrap .content .slider-block p {
        margin-bottom: 35px; }
        @media (min-width: 768px) {
          .each-quiz .slider-block-wrap .content .slider-block p {
            margin-bottom: 45px; } }
      .each-quiz .slider-block-wrap .content .slider-block .add-parfum {
        cursor: pointer; }

.each-quiz .answers {
  padding: 0;
  max-width: 720px;
  margin: 0 auto; }
  .each-quiz .answers .each-answer {
    display: block;
    padding: 2.3vh 0; }

.thankyou .header {
  padding: 25vh 0 10vh 0;
  margin: 0 15px; }
  .thankyou .header h1 {
    margin: 0;
    padding: 0 0 25px 0;
    text-align: center; }
    .thankyou .header h1 img {
      width: 280px; }
  .thankyou .header h2 {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 25px; }
  .thankyou .header p {
    width: 80%;
    margin: 0 auto; }

.side-arrow-navs {
  width: 100%;
  height: 0;
  position: fixed;
  top: 23vh; }
  .side-arrow-navs a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    font-size: 20px; }
    .side-arrow-navs a.icon-left-open-big {
      left: 0px; }
    .side-arrow-navs a.icon-right-open-big {
      right: 0px; }

.v2 .side-arrow-navs {
  top: 35vh; }
