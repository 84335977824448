/* Scss Document */
#pbar {
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  color: #FFF;
  background-color: #000000; }
  #pbar #bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    content: ' ';
    width: 100%;
    background-color: #6898AD; }
